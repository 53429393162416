export const gfppBtns = [
  'open_connect_panel',
  'widget_settings',
  'widget_elements',
  'widget_layout',
  'widget_design',
  'widget_animation',
  'stretch',
  'help',
] as const;

export const pgGfppBtns = ['open_expand_mode'] as const;

export const app_settings = ['connected', 'disconnected'] as const;

export const viewer = ['ig_user_name', 'ig_hashtags', 'ig_icon'] as const;
export const account_settings = ['connect'] as const;

// const btnNameToParentViewMap: Record<string, ParentViewType> = {
//   gfppBtns: 'editor',
//   widgetDesignBtns: 'widget_design',
//   widgetLayoutBtns: 'widget_layout',
//   elementsPanelBtns: 'widget_elements',
//   stretchBtns: 'stretch',
//   appSettings: 'app_settings',
// };