import {
  FlowAPI,
  FlowEditorSDK,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';
import type { AppManifest } from '@wix/platform-editor-sdk';
import {
  EditorSDKUtils,
  installWidget,
  modifyAppManifest,
} from './utils/Editor';
import { editorAppEventListener } from './utils/Editor/editorEventListeners';
import { bi } from './bi';

let flowApi;

export const editorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: any,
  flowAPI: FlowAPI,
) => {
  flowApi = flowAPI;
  if (options.firstInstall) {
    installWidget(editorSDK);
  }

  await editorAppEventListener(editorSDK, flowAPI, options);
};

export const getAppManifest: GetAppManifestFn = async (
  options,
  editorSDK,
  context,
  flowAPI,
): Promise<AppManifest> => {
  return modifyAppManifest(options, editorSDK, flowAPI);
};

export const exports = (editorSDK) => ({
  private: {
    biReportConnectAccount: async () => {
      const editorSDKUtils = new EditorSDKUtils(editorSDK);
      const instance = await editorSDKUtils.getInstance();
      bi({ flowAPI: flowApi, app_instance: instance }).buttonClick('connect');
    },
  },
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      const editorSDKUtils = new EditorSDKUtils(editorSDK);
      const selectedPreset = await editorSDKUtils.getPreset(widgetRef);
      return selectedPreset.style;
    },
  },
});
